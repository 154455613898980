import { LanguageContext } from "../../common/language";
import FixtureLabel from "../../common/FixtureLabel";
import LabelledAttr from "../../common/LabelledAttr";
import DetailPage from "./DetailPage";
import Select from "../../components/Select";
import React from "react";
import { useNavigation } from "@react-navigation/native";
import { Text, TextInput } from "react-native-paper";
import { View, ViewStyle } from "react-native";
import { MEMBERSHIP_CATEGORIES, MembershipRoles } from "../../common/model/project/membership";

type RoleProps = {
  roles: MembershipRoles;
  onRolesChanged?: (roles: MembershipRoles) => void;
}

function Roles({ roles, onRolesChanged, style }: RoleProps & { style?: ViewStyle }) {
  const { ph } = React.useContext(LanguageContext);
  const keys = ['none', 'normal-user', 'admin-user'];
  const labels = keys.reduce((m, k) => ({ ...m, [k]: ph(k) as string }), {}) as { [k: string]: string };
  return (
    <View style={{ ...style, gap: 4 }}>
      <Text variant="headlineSmall">{ph('roles') as string}</Text>
      <View style={{ flexDirection: "row", flexWrap: "wrap" }} >
        {
          MEMBERSHIP_CATEGORIES.map(category => {
            const role = roles[category] || {};
            const selectedEntry = role.admin ?
              'admin-user' : (role.user ? 'normal-user' : 'none');
            return (
              <View key={category} style={{ margin: 8 }}>
                <FixtureLabel text={ph(category) as string} />
                {onRolesChanged ?
                  <Select entries={keys}
                    entryKey={key => key}
                    entryLabel={key => labels[key]}
                    selectedEntry={selectedEntry}
                    onEntryChange={key => onRolesChanged({
                      ...roles,
                      [category]: (
                        key === 'admin-user' ? { user: true, admin: true } :
                          (key === 'normal-user' ? { user: true } : {})
                      )
                    })}
                  /> :
                  <Text style={{ marginRight: 8 }}>{labels[selectedEntry]}</Text>
                }
              </View>
            );
          })
        }
      </View>
    </View>
  );
}

type MembershipDetailPageProps = {
  isNew?: boolean;
  isInvitation?: boolean;
  readOnly?: boolean;
  isLoading?: boolean;
  userName?: string;
  email: string;
  onEmailChanged?: (email: string) => void;
  onSubmit?: () => void;
  submitInProgress?: boolean;
  deleteAction?: () => void;
} & RoleProps

export default function MembershipDetailPage({
  isNew, isInvitation, readOnly, isLoading,
  userName, email, onEmailChanged, onSubmit, submitInProgress, deleteAction,
  ...props
}: MembershipDetailPageProps) {
  const { ph } = React.useContext(LanguageContext);
  const { goBack } = useNavigation();
  return (
    <DetailPage
      title={ph((isNew ? 'new-' : '') + (isInvitation ? 'invitation' : 'member')) as string}
      isLoading={isLoading}
      deleteItem={deleteAction}
      {...(isNew ? { onCancel: goBack, onDone: onSubmit, isDoing: submitInProgress } : {})}
    >
      <View style={{ margin: 16, gap: 8 }}>
        {isInvitation || !userName ? null :
          <LabelledAttr label={ph('name') as string}>{userName}</LabelledAttr>}
        {
          onEmailChanged ?
            <TextInput
              label={ph('email') as string} value={email} onChangeText={onEmailChanged}
            /> :
            <LabelledAttr label={ph('email') as string}>
              {email}
            </LabelledAttr>
        }
        <Roles style={{ marginTop: 8 }} {...props} />
      </View>
    </DetailPage>
  );
}
