function tokenise(s: string | undefined) {
  return s?.toLocaleLowerCase().split(/\s+/).filter(token => token.length);
}

export function tokeniseQuery(query: string) {
  return tokenise(query);
}

export function matchesSearch(queryTokens: string[], candidateTokens: (string | undefined)[]) {
  return candidateTokens.some(candidateToken => queryTokens.some(
    queryToken => tokenise(candidateToken)?.some(t => t.startsWith(queryToken))
  ));
}

export type TokenisedCandidate = {
}

type Match = {
  isExact?: boolean,
}

export function matchList(
  queryTokens: string[],
  tokenisedCandidates: TokenisedCandidate[]
) {
}
