import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { DocumentReference, DocumentSnapshot, FieldValue } from "@atgof-firebase/firebase";
import React from "react";
import { LanguageContext } from "../common/language";
import DetailPage from "../components/pages/DetailPage";
import FixtureLabel from "../common/FixtureLabel";
import PhotoStrip from "../components/PhotoStrip";
import ReferentSelector from "../components/ReferentSelector";
import { RootStackParamList } from "../types";
import PASheet from "../components/PASheet";
import FieldsRow from "../components/fields/FieldsRow";
import { isCharacterCategory, SheetCategory } from "../common/sheet";
import { ProjectContext } from "../data/projectContext";
import { PhraseKey, safePh } from "../common/phrases";
import { StyleProp, View, ViewStyle } from "react-native";
import SceneInfo from "../components/SceneInfo";
import { useNavigation } from "@react-navigation/native";
import { UserContext } from "../data/userContext";
import LabelledAttr from "../common/LabelledAttr";
import { SheetSections } from "../components/SheetSections";
import { cardNumberAttrs, generateNextSlateAttrs, removeSlate } from "../data/team";
import { SegmentedButtons } from "react-native-paper";
import { referencePathForScene } from "../data/backend/downloader";
import { sceneLabel, toScene } from "../common/scene";
import { episodeForScene } from "../common/episode";
import { useDocumentReference } from "../data/firestore";
import _ from "lodash";
import { useDocument } from "../data/useDocument";
import { getDocumentReference } from "../common/firestore";

function generateSubjectField(
  key: string, referent: DocumentSnapshot | undefined, keyInReferent: string
) {
  const options = referent?.get(keyInReferent) as string[] | undefined;
  if (!options) return;
  return { key: key, kind: { stringSelect: options } };
}

function LabelledUserName(
  { sheet, fieldName, fieldPhraseK }:
    {
      sheet: DocumentSnapshot, fieldPhraseK: PhraseKey, fieldName: string,
    }
) {
  const { ph } = React.useContext(LanguageContext);
  const [user, setUser] = React.useState(undefined as DocumentSnapshot | undefined);
  const userRef = sheet.get(fieldName) as DocumentReference | undefined;
  React.useEffect(() => {
    if (!userRef) {
      setUser(undefined);
      return;
    }
    return userRef.onSnapshot(setUser);
  }, [userRef?.path]);
  return (
    <LabelledAttr label={ph(fieldPhraseK) as string} small style={{ marginRight: 16, marginBottom: 16 }}>
      {user?.get('displayName') || ''}
    </LabelledAttr>
  );
}

function MetadataSection({ sheet }: { sheet: DocumentSnapshot }) {
  return (
    <View style={{ flexDirection: 'row', margin: 4 }}>
      {[['created-by', 'createdBy'], ['last-modified', 'lastModifiedBy']].map(
        ([phraseKey, k]) =>
          <LabelledUserName
            key={k} sheet={sheet} fieldName={k} fieldPhraseK={phraseKey}
          />
      )}
    </View>
  );
}

export default function SheetScreen({ route }: NativeStackScreenProps<RootStackParamList, 'sheet'>) {
  const pathOrPromise = route.params.path;
  const isNew = route.params.isNew === 'true';
  const { goBack } = useNavigation();
  const { adminCategories } = React.useContext(ProjectContext);
  const { user } = React.useContext(UserContext);
  const { ph, language } = React.useContext(LanguageContext);
  const [sheetPath, setSheetPath] = React.useState(undefined as string | undefined);
  React.useEffect(() => {
    if (pathOrPromise instanceof Promise) pathOrPromise.then(setSheetPath);
    else setSheetPath(pathOrPromise);
  }, [pathOrPromise]);
  const sheet = useDocument(useDocumentReference(sheetPath));
  const slate: string | undefined = sheet?.get('pa:slate');
  const teamRef: DocumentReference | undefined = sheet?.get('team');
  const [scene, setReferent] = React.useState<DocumentSnapshot>();
  const handleTeamOrSceneChange = React.useCallback(
    async (sceneRef: DocumentReference | undefined, newTeamRef: DocumentReference | undefined) => {
      if (newTeamRef?.path === teamRef?.path) return;
      await removeSlate(teamRef, sheet?.id, slate);
      const attrs = await generateNextSlateAttrs(sceneRef);
      sheet?.ref.update([...cardNumberAttrs, 'pa:slate'].reduce(
        (data, k) => ({ ...data, [k]: attrs[k] || FieldValue.delete() }),
        { team: newTeamRef || FieldValue.delete() }));
    },
    [teamRef?.path, slate, sheet]);
  const handleTeamChange = React.useCallback(
    (newTeamRef: DocumentReference | undefined) => handleTeamOrSceneChange(scene?.ref, newTeamRef),
    [handleTeamOrSceneChange, scene?.ref.path]);
  const deleteSheet = React.useCallback(
    () => {
      if (!sheet) return;
      sheet.ref.update({ deleted: FieldValue.serverTimestamp(), lastModifiedBy: user.ref })
        .then(() => {
          removeSlate(teamRef, sheet?.id, slate);
          goBack();
        });
    },
    [sheet, teamRef?.path, slate, user.ref.path]);
  const setScene = React.useCallback(
    async (ref: DocumentReference | undefined) => {
      const doc = await ref?.get();
      setReferent(doc);
      await handleTeamOrSceneChange(ref, doc?.get('team'));
      await sheet!.ref.update({
        scene: ref,
        episode: ref === null ? null : episodeForScene(ref),
        lastModifiedBy: user.ref
      }); // TODO Error handling
    },
    [sheet, user.ref.path, handleTeamOrSceneChange]);
  const category = sheet?.get('category') as SheetCategory | undefined;
  const referentPath = (sheet?.get('scene') as DocumentReference | undefined)?.path;
  const isCharacterCat = isCharacterCategory(category);
  const subjectField = (
    isCharacterCat ?
      generateSubjectField('character', scene, 'cast') :
      (category === 'arts' ? generateSubjectField('set', scene, 'sets') : undefined)
  );

  React.useEffect(() => {
    const ref = getDocumentReference(user, referentPath);
    if (!ref) {
      setReferent(undefined);
      return;
    }
    return ref.onSnapshot(setReferent);
  }, [user, referentPath]);

  // TODO When sheet/sheetRef isn't set

  const isPASheet = category === 'pa';

  const titleParts = [
    subjectField && sheet?.get(subjectField.key),
    scene && sceneLabel(scene.ref.path),
    isPASheet && sheet?.get('pa:slate')
  ].filter(x => x);

  return (
    <DetailPage
      title={
        isNew && titleParts.length < 2 ?
          (safePh(language, 'new-' + category + '-sheet') || ph('new-sheet')) as string :
          titleParts.join(' - ') || ph('edit-sheet') as string
      }
      deleteItem={deleteSheet}
    >
      {sheet &&
        <View style={{ paddingTop: 16 }}>
          {adminCategories.includes('admin') && <MetadataSection sheet={sheet} />}
          <View style={{ flexDirection: "row", marginBottom: 16, flexWrap: "wrap" }}>
            <ReferentSelector kind="scene" referent={scene?.ref}
              style={{ marginTop: 8, marginBottom: 8 }}
              onReferentChange={setScene}
            />
            {subjectField && <FieldsRow key="subject" doc={sheet!} fields={[subjectField]} />}
          </View>
          {scene &&
            <SceneInfo style={{ marginBottom: 16 }}
              scene={toScene(scene)}
              sceneRefToEdit={isPASheet ? scene.ref : undefined}
              handleTeamChange={isPASheet ? handleTeamChange : undefined}
            />}
          {category &&
            <PhotoStrip
              sheetRef={sheet.ref} sheetCategory={category}
              referencePath={referencePathForScene(scene?.ref, sheet?.id)}
              style={{ marginBottom: 16 }}
            />}
          {isCharacterCat &&
            <View style={{ marginBottom: 16 }}>
              <FixtureLabel text={ph('look') as string}
                style={{ fontSize: 20, marginBottom: 8 }} />
              <View style={{ flexDirection: "row" }}>
                <SegmentedButtons
                  value={sheet?.get('look')}
                  onValueChange={v => sheet!.ref.update(
                    { look: v, lastModifiedBy: user.ref }
                  )}
                  buttons={
                    [{ value: 'day', icon: 'white-balance-sunny' },
                    { value: 'night', icon: 'weather-night' }].map(({ value }) => (
                      { value, label: ph(value) as string }
                    ))
                  }
                />
              </View>
            </View>}
          {isPASheet ?
            scene &&
            <PASheet sheet={sheet} scene={scene} /> :
            <SheetSections doc={sheet} category={category} />}
        </View>
      }
    </DetailPage>
  );
}
