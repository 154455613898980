import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import MembershipDetailPage from "../components/pages/MembershipDetailPage";
import { BackendContext, transact } from "../data/backend";
import { useProject } from "../data/useProject";
import { UserContext } from "../data/userContext";
import { RootStackParamList } from "../types";
import { MembershipRoles } from "../common/model/project/membership";

export default function NewInvitationScreen({ }: NativeStackScreenProps<RootStackParamList, 'newInvitation'>) {
  const { goBack } = useNavigation();
  const project = useProject();
  const { user } = React.useContext(UserContext);
  const backend = React.useContext(BackendContext);
  const [inProgress, setInProgress] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [roles, setRoles] = React.useState({} as MembershipRoles);
  function submit() {
    setInProgress(true);
    transact(
      backend, 'create-invitation', () =>
      project!.collection("invitations").doc(email).set(
        { email: email, roles: roles, lastModifiedBy: user.ref })
    ).then(goBack).finally(() => setInProgress(false));
  }
  const onSubmit = email.length > 0 && Object.keys(roles).length > 0 ? submit : undefined;
  return (
    <MembershipDetailPage
      isInvitation isNew
      email={email} onEmailChanged={setEmail}
      roles={roles} onRolesChanged={setRoles}
      onSubmit={onSubmit} submitInProgress={inProgress}
    />
  );
}
