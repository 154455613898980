import { z } from 'zod';
import { deletable } from '../deletable';
import { emailAddressSchema } from '../contact/emailAddress';
import { userReference } from '../user';
import { SHEET_CATEGORIES } from '../../sheet';
import { characterReference } from './character';

export const MEMBERSHIP_CATEGORIES = [
  ...SHEET_CATEGORIES, 'cast', 'postProduction', 'admin'
] as const;

export type MembershipCategory = typeof MEMBERSHIP_CATEGORIES[number]

const roleSchema = z.object({
  user: z.boolean().optional(),
  admin: z.boolean().optional(),
  characters: z.array(characterReference).optional() // TODO This is just for the 'cast' role
});

const rolesSchema = z.record(z.enum(MEMBERSHIP_CATEGORIES), roleSchema);

export type MembershipRoles = z.infer<typeof rolesSchema>;

export const membershipSchema = deletable.extend({
  user: userReference,
  displayName: z.string(),
  email: emailAddressSchema,
  lastModifiedBy: userReference,
  roles: rolesSchema,
});
